import React from "react";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import Section from "../../../containers/Section";
import FeaturesCard from "../../FeaturesCard";
import { PAGE } from "../../../constants/pages";

interface WhyPerpetuaProps {
  gradientTitle: string[];
  description: string;
  gradientPosition: number;
  features: any;
}

const WhyPerpetua: React.FC<WhyPerpetuaProps> = props => {
  const {
    gradientTitle,
    description,
    gradientPosition,
    features
  } = props;


  return (
      <Section className="md:py-18">
        <div className="w-full mx-auto lg:max-w-4xl text-center">
          <GradientTitle
            gradientPosition={gradientPosition}
            className="max-w-xl lg:max-w-4xl mx-auto"
            color="purple"
            variant={HeaderVariant.h2}
            title={gradientTitle}
            lineBreak={false}
          />
          <Paragraph className="text-2xl mx-auto font-semibold">
            {description && description}
          </Paragraph>
        </div>
        <FeaturesCard border={true} backgroundColor="transparent" page={PAGE.AFFILIATE} features={features} />
      </Section>
  );
};

export default WhyPerpetua;
