import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import Section from "../../../containers/Section";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { nav } from "../../../utils/navigation";

interface PerpetuaBenchmarkerProps {
  title?: string;
  description?: string[];
  cta?: {
    text: string;
    route: string;
  };
}

const PerpetuaBenchmarker: React.FC<PerpetuaBenchmarkerProps> = props => {
  const { title, description, cta } = props;

  return (
    <div className="py-24 lg:mx-0 bg-blue-3 rounded-tl-large rounded-br-large">
      <div className="px-6 sm:px-12 xl:px-6 max-w-8xl mx-auto">
        <div className="items-center flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <Header
              variant={HeaderVariant.h3}
              className="text-center md:text-left"
            >
              {title}
            </Header>
            <div className="lg:max-w-md xl:max-w-xl">
              {description &&
                description.map((item, index) => {
                  return (
                    <Paragraph
                      key={index}
                      className="text-center md:text-left mt-3"
                    >
                      {item}
                    </Paragraph>
                  );
                })}
            </div>

            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={cta?.text}
              className="mx-auto md:mx-0 mt-6 mb-6 lg:mb-0"
              onClick={() => {
                nav(cta?.route);
              }}
            />
          </div>
          <div className="w-full lg:w-1/2">
            <StaticImage
              src="../../../images/affiliate-program/perpetua-benchmarker-2-summary.png"
              alt="Perpetua Benchmarker screenshot"
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerpetuaBenchmarker;
