import React from "react";
import { HeaderVariant } from "../../Header";
import GradientTitle from "../../GradientTitle";
import Section from "../../../containers/Section";

interface GetStartedProps {
  gradientTitle: string[];
  gradientPosition: number;
  steps: string[];
}

const GetStarted: React.FC<GetStartedProps> = props => {
  const { gradientTitle, gradientPosition, steps } = props;

  return (
    <Section className="py-18">
      <div className="w-full mx-auto lg:max-w-4xl text-center">
        <GradientTitle
          gradientPosition={gradientPosition}
          className="max-w-[14rem] sm:max-w-max mx-auto"
          color="purple"
          variant={HeaderVariant.h2}
          title={gradientTitle}
          lineBreak={false}
        />
      </div>
      <div className="flex flex-col lg:flex-row mx-auto justify-center gap-10 mt-16">
        {steps.map((step, index) => {
          return (
            <div className="flex flex-col items-center lg:w-1/6">
              <div className="w-12 h-12 rounded-full bg-blue-3-light flex items-center justify-center">
                <p className="text-2xl text-blue-2-dark font-semibold">{index + 1}</p>
              </div>
              <p className="text-center text-xl mt-4 font-semibold">{step}</p>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default GetStarted;
