import React from "react";
import Section from "../../../containers/Section";
import { StaticImage } from "gatsby-plugin-image";

interface QuoteProps {
  quote: { text: string; author: { name: string } };
}

const Quote: React.FC<QuoteProps> = props => {
  const { quote } = props;

  return (
    <Section className="py-12 lg:py-18">
      <div className="bg-gradient-to-r from-blue-3 to-indigo-3-light p-8 sm:p-12 shadow-lg max-w-[78rem] rounded-2xl mx-auto">
        <p className="text-2xl sm:text-4xl mx-auto font-semibold text-indigo-3-dark">
          {quote?.text}
        </p>
        <div className="flex flex-row items-center mt-6">
          <StaticImage
            src="../../../images/affiliate-program/thomas.png"
            alt="Thomas Ropel"
            className="w-16 h-16 rounded-full"
          />
          <small className="ml-4 text-indigo-3-dark font-semibold">
            {quote?.author?.name}
          </small>
        </div>
      </div>
    </Section>
  );
};

export default Quote;
