import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import Accordion from "../../Accordion";

interface FaqsProps {
  faqs: { title: string; content: string }[];
}

const Faqs: React.FC<FaqsProps> = props => {
  const { faqs } = props;

  return (
    <Section className="py-18">
      <div className="w-full mx-auto lg:max-w-4xl">
        <Header variant={HeaderVariant.h3} className="text-center">
          <span className="text-5xl">FAQ</span>
        </Header>
        <div className="mt-18">
          {faqs?.map((faq, index) => {
            return (
              <Accordion
                key={index}
                title={faq?.title}
                content={faq?.content}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default Faqs;
