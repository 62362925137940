import React, { useState } from "react";
import cn from "classnames";

interface AccordionProps {
  title: any;
  content?: any;
  type?: string;
  maxContentHeight?: string;
}

const Accordion: React.FC<AccordionProps> = props => {
  const { title, content, type, maxContentHeight } = props;
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div
      aria-expanded={accordionOpen ? "true" : "false"}
      className={cn("panel border-2 rounded-lg px-5 mb-5 cursor-pointer")}
      onClick={() => setAccordionOpen(!accordionOpen)}
      {...(type === "faq" && {
        itemscope: "somevalue",
        itemProp: "mainEntity",
        itemType: "https://schema.org/Question"
      })}
    >
      <div
        className={cn(
          "flex justify-between items-center title relative before:transition before:duration-300 before:rotate-[-90deg] before:absolute before:content-[''] before:right-[0px] before:top-[50%] before:w-[22px] before:h-[3px] before:mt-[-2px] before:bg-indigo-1 after:absolute after:content-[''] after:right-[0px] after:w-[22px] after:h-[3px] after:mt-[-2px] after:bg-indigo-1",
          accordionOpen ? "before:rotate-0" : ""
        )}
      >
        <p
          {...(type === "faq" && { itemProp: "name" })}
          className="text-gray-3-dark text-2xl py-5 font-semibold w-[94%]"
        >
          {title}
        </p>
      </div>
      <div
        {...(type === "faq" && {
          itemscope: "",
          itemProp: "acceptedAnswer",
          itemType: "https://schema.org/Answer"
        })}
        className={cn(
          "text-gray-3-dark md:text-2xl text-left overflow-hidden transition-[max-height] duration-500 ease-in",
          accordionOpen ? maxContentHeight : "max-h-0"
        )}
      >
        {Array.isArray(content) ? (
          content?.map((item, index) => {
            return (
              <p key={index} className="font-semibold text-lg mb-2">
                {item?.title}
                {item?.subItems &&
                  item?.subItems?.map((subItem, index) => {
                    return (
                      <li key={index} className="ml-4 font-normal">
                        {subItem}
                      </li>
                    );
                  })}
              </p>
            );
          })
        ) : (
          <span className="pb-2 block" {...(type === "faq" && { itemProp: "text" })}>
            {content?.split("/n")?.map(item => {
              return <span className="block mb-4">{item}</span>;
            })}
          </span>
        )}
      </div>
    </div>
  );
};

export default Accordion;
